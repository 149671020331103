/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import './app/themes/palette';
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
@include mat.core();
// Default colors

@import './src/app/themes/dark-theme.scss';

.light {
  $light-theme-primary: mat.define-palette($rina-blue, 500, 100, 800);
  $light-theme-accent: mat.define-palette($rina-lightblue, 500, 100, 800);
  $light-theme-warn: mat.define-palette($rina-warn);
  $light-theme: mat.define-light-theme($light-theme-primary, $light-theme-accent, $light-theme-warn);
  @import './src/app/themes/light-theme.scss';
  @include mat.all-component-themes($light-theme);
}

.dark {
  $dark-theme-primary: mat.define-palette($rina-lightblue, 500, 100, 800);
  $dark-theme-accent: mat.define-palette($rina-darkblue, 500, 100, 800);
  $dark-theme-warn: mat.define-palette($rina-warn);
  $dark-theme: mat.define-dark-theme($dark-theme-primary, $dark-theme-accent, $dark-theme-warn);
  @include mat.all-component-themes($dark-theme);
  @include mat.all-component-colors($dark-theme);
}


html {
  height: 100%;
}

mat-toolbar {
  .spacer {
    flex: 1 1 auto;
  }
}


html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #13294b;
  }

  ::-webkit-scrollbar-thumb {
    background: #3eb1c8;
    border-radius: 6px;
  }
}

.cancelBtn:hover {
  color: red;

  .mat-button-focus-overlay {
    opacity: .00;
  }
}

rina-sidenav-item {
  .menu-item {
    transition: box-shadow 300ms ease-in-out;
    box-shadow: inset 0 0 0 0 rgba(255, 255, 255, 0.0);

    &:hover {
      box-shadow: inset 4px 0px 0px 0px mat.get-color-from-palette($rina-lightblue, 500, 0.65);
    }

    &.active {
      box-shadow: inset 4px 0px 0px 0px mat.get-color-from-palette($rina-lightblue, 200, 1);
    }
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.not-allowed {
  white-space: pre-wrap;
}

.hidden-header {
  .mat-tab-header {
    display: none;
  }
}

.feature-inner-panel {
  .mat-expansion-panel-body {
    padding: 0 14px 6px;
  }
}
.hidden{
  display: none !important;
}
.custom-stepper {
  display: flex !important;

  .mat-horizontal-stepper-wrapper {
    display: flex;
    flex-direction: row;
  }

  .mat-horizontal-stepper-header-container {
    display: block !important;
  }

  .mat-horizontal-content-container {
    width: 86rem;
    height: 80vh;
    margin: 0 auto;

    .cdk-virtual-scroll-viewport {
      height: 65vh !important;
    }
  }

  .mat-step-icon {
    display: none
  }

  .mat-stepper-horizontal-line {
    margin: 0 !important;
  }
}

.error-snackbar {
  background-color: indianred !important;

  .mat-simple-snack-bar-content {
    color: white;
  }
}

@keyframes cssAnimation {
  to {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

.loader-container.index {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1006%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='url(%23SvgjsLinearGradient1007)'%3e%3c/rect%3e%3cpath d='M1440 0L749.03 0L1440 72.26z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M749.03 0L1440 72.26L1440 100.03L635.74 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M635.74 0L1440 100.03L1440 380.6L442.52 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M442.52 0L1440 380.6L1440 439.51L407.95 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 560L451.9 560L0 362.81z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 362.81L451.9 560L892.55 560L0 244.12z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 244.12L892.55 560L983.5899999999999 560L0 172.75z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 172.75L983.5899999999999 560L1215.9499999999998 560L0 113.03999999999999z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1006'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='15.28%25' y1='-39.29%25' x2='84.72%25' y2='139.29%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1007'%3e%3cstop stop-color='%230e2a47' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(62%2c 177%2c 200%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
  width: 100vw;
  background-size: cover;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  z-index: 2000;
  position: absolute;
  -moz-animation: cssAnimation 1s ease-in 9999s forwards;
  /* Firefox */
  animation: cssAnimation 1s ease-in 9999s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 1s ease-in 9999s forwards;
  /* Opera */
  animation-fill-mode: forwards;
}

.spinner {
  margin: 20vh auto 0;
  width: 90px;
  text-align: center;
}

rina-edit {
  .hidden-scroll {
    .mat-tab-body-wrapper {
      .mat-tab-body-content {
        overflow: hidden !important;
      }
    }
  }

  .mat-expansion-panel .mat-expansion-panel-body {
    padding: 0;
  }
}

.spinner > div {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce3 {
  background-color: #13294b;
}

.spinner .bounce1 {
  background-color: #3eb1c8;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  background-color: #0076a5;
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
