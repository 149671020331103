@use '@angular/material' as mat;

@include mat.core();
@import './palette';
$light-primary: mat.get-color-from-palette($rina-lightgray, 500, 100);
$light-accent: mat.get-color-from-palette($rina-lightgray, 500, 100);
$light-background: mat.get-color-from-palette($rina-lightgray, 700, 100);
$light-warn: mat.get-color-from-palette($rina-warn, 400, 100);
.user-menu {
  background-color: mat.get-color-from-palette($rina-grayweb, 800);
}
rina-sidenav-item {
  .active {
    border-left: 3px solid rgba(0, 0, 0, 0.87);
  }
}

// theming generic components

rina-entity-assigner,
rina-role-edit {
  .selected-item {
    background-color: mat.get-color-from-palette($rina-grayweb, 800);
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    background: #aaa;
    color: mat.get-color-from-palette($rina-grayweb, 800);
  }

  .panel-style {
    background-color: mat.get-color-from-palette($rina-grayweb, 800);
  }
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: mat.get-color-from-palette($rina-blue, 500);
}
