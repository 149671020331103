@use '@angular/material' as mat;

@include mat.core();
@import './palette';

$dark-primary: mat.get-color-from-palette($rina-darkgray, 500, 100);
$dark-accent: mat.get-color-from-palette($rina-lightgray, 500, 100);
$dark-background: mat.get-color-from-palette($rina-darkgray, 700, 100);
$dark-warn: mat.get-color-from-palette($rina-warn, 400, 100);
.mat-flat-button.mat-primary {
  color: rgba(255,255,255,0.8);
}
rina-entity-assigner,
rina-role-edit {
  .selected-item {
    background: mat.get-color-from-palette($rina-darkgray, 900);
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    background: mat.get-color-from-palette($rina-darkgray, 800);
    color: whitesmoke;
  }

  .panel-style {
    background: mat.get-color-from-palette($rina-darkgray, 800);
  }
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: mat.get-color-from-palette($rina-lightblue, 500);
}
